import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {
  ApiResponse,
  ErrorResponse,
  SuccessResponse,
  User
} from '../synaps-media.types';

interface AuthResponse {
  user: User;
  token?: string;
}
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  me(): Observable<User> {
    return this.http.get(`${this.baseUrl}/users/me`).pipe(
      map((res) => res as User)
    );
  }

  register(email, password, token): ApiResponse<AuthResponse> {
    return this.http.post<SuccessResponse<AuthResponse>>(`${this.baseUrl}/users`, {
      email,
      password,
      token,
    });
  }

  verifyToken(code) {
    return this.http.put(`${this.baseUrl}/users/me/verify`, {
      code,
    });
  }

  resendToken() {
    return this.http.post(`${this.baseUrl}/users/me/verify`, {});
  }

  /**
   * Password reset
   *
   * @param email Email address to send password reset
   */
  passwordReset(email) {
    return this.http.delete(`${this.baseUrl}/users/me/password`, {
      params: {
        email,
      },
    });
  }

  createNewPassword(token, newPassword) {
    return this.http.post(`${this.baseUrl}/users/me/password`, {
      token,
      newPassword,
    });
  }

  checkPassword(currentPassword: string) {
    return this.http.post(`${this.baseUrl}/users/me/password/check`, {
      currentPassword,
    });
  }

  changePassword(newPassword) {
    return this.http.put(`${this.baseUrl}/users/me/password`, {
      newPassword,
    });
  }

  login(email: string, password: string): ApiResponse<AuthResponse> {
    return this.http
      .post<SuccessResponse<AuthResponse>>(`${this.baseUrl}/sessions`, {
        email,
        password,
      });
  }

  signOut() {
    return this.http.delete(`${this.baseUrl}/sessions`);
  }
}
